<template>
	<nav class="mobile-nav-bar">
		<div class="nav-bar-section">
			<div class="menu-button" @click="$emit('toggle-nav')">
				<div class="menu-icon" />
				<span>{{ $t('buttonMenu') }}</span>
			</div>
		</div>
		<divider />
		<div class="nav-bar-section">
			<nuxt-link :to="localePath('rooms')">
				<div class="rooms-icon" />
				<span>{{ $t('buttonRooms') }}</span>
			</nuxt-link>
		</div>
		<divider />
		<div class="nav-bar-section">
			<book-button class="reserve-now">
				<div class="book-icon" />
				<span>{{ $t('bookNow') }}</span>
			</book-button>
		</div>
		<divider />
		<div class="nav-bar-section">
			<a :href="config.public.siteUrlGroup + vacancyPage.filename" target="_blank">
				<div class="vacancies-icon" />
				<span>{{ $t('buttonJobs') }}</span>
			</a>
		</div>
	</nav>
</template>

<script setup>
const { groupsPages, fetchGroupsPages } = await useGroupsPages();
const { $i18n } = useNuxtApp();

const config = useRuntimeConfig();

await fetchGroupsPages();

$i18n.onBeforeLanguageSwitch(async () => {
	await fetchGroupsPages();
});

const vacancyPage = computed(() => groupsPages.value.find((page) => page.slug === 'vacancies-index'));

defineEmits(['toggle-nav']);
</script>

<style lang="scss" scoped>
.mobile-nav-bar {
	display: none;
	position: fixed;
	bottom: 85px;
	left: 20px;
	right: 20px;
	max-width: 360px;
	box-shadow: 0 24px 24px 0 rgba(0 0 0 / 15%);
	background: var(--light-background-color);
	z-index: 10;
	margin: 0 auto;
	padding: 12px;
	border-radius: 6px;

	.divider {
		margin: 0 9px;
	}

	.nav-bar-section {
		padding: 0 9px;

		> a,
		> div {
			display: flex;
			flex-flow: column wrap;
			justify-content: center;
			align-items: center;
			text-align: center;
			font-size: 11px;
			color: var(--body-color);
			text-decoration: none;
			text-transform: uppercase;
			padding: 0;
			margin: 0;
			cursor: pointer;

			&:hover {
				color: #000;
			}

			&::after {
				display: none;
			}
		}
	}

	.menu-icon,
	.rooms-icon,
	.book-icon,
	.vacancies-icon {
		width: 42px;
		height: 38px;
		background: url('~/assets/images/menu-button-icon-new.png') no-repeat center center;
		background-size: 42px;
		margin: 0 !important;
	}

	.rooms-icon {
		background: url('~/assets/images/rooms-button-icon.png') no-repeat center center;
		background-size: 42px;
	}

	.book-icon {
		background: url('~/assets/images/book-button-icon.png') no-repeat center center;
		background-size: 42px;
	}

	.vacancies-icon {
		background: url('~/assets/images/vacancies-button-icon.png') no-repeat center center;
		background-size: 42px;
	}
}

@media (max-width: 1200px) {
	.mobile-nav-bar {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
	}
}
</style>
