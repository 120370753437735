<template>
	<div
		id="top"
		class="container"
		:class="{ 'no-heading-image': !page.headingImage, 'has-video': page.vimeoIDLandscape }"
	>
		<Html :lang="locale" />
		<Head v-if="page">
			<Title>{{ page.metaTitle }}</Title>
			<Meta name="description" :content="page.metaDescription" />
			<Meta name="robots" :content="page.metaRobots" />
			<Hreflangs v-if="page.slug !== '404'" :langmenu="page.langmenu" />
		</Head>

		<navigation />

		<heading-image
			v-if="page && page.headingImages && page.headingImages.length"
			:heading-image="page.headingImages[0]"
		/>

		<slot />

		<social-section
			v-if="page && page.showInstagramFeed"
			:image="defaults[locale].homepage.sectionSocialmediaImage"
			:header="defaults[locale].homepage.sectionSocialmediaTitle"
			:url="defaults[locale].homepage.sectionSocialmediaUrl"
			:handle="defaults[locale].homepage.sectionSocialmediaHandle"
			:snapwidget-id="defaults[locale].website.snapwidgetId"
		/>

		<footers />

		<pop-up
			v-if="page && defaults[locale].popUp.content"
			:image="defaults[locale].popUp.image"
			:content="defaults[locale].popUp.content ?? ''"
		/>
		<cookie-consent
			v-if="page && defaults[locale].cookieConsent.content"
			:content="defaults[locale].cookieConsent.content ?? ''"
			:position="defaults[locale].cookieConsent.position"
		/>
		<to-top />
		<component
			:is="'script'"
			async
			src="https://webchat.runnr.ai/chat/chat-widget.js"
			type="application/javascript"
			data-widget="chat"
			data-title="Chat with Hotel Mai Amsterdam"
			data-btn-title="Chat with us"
			data-primary-color="#be8c40"
			crossorigin="anonymous"
		/>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();

await fetchPage();

const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.fix-on-scroll');
	if (window.pageYOffset >= 44) {
		fixedElements.forEach((el) => el.classList.add('fixed'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('fixed'));
	}
};

const loadAnimations = () => {
	const fadeElements = document.querySelectorAll('.fade-animate');
	const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.1,
	};
	const callbacks = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add('active');
			}
		});
	};
	const observer = new IntersectionObserver(callbacks, options);
	fadeElements.forEach((element) => {
		observer.observe(element);
	});
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
	window.addEventListener('scroll', loadAnimations);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
	window.addEventListener('scroll', loadAnimations);
});

const currentUrl = config.public.siteUrl + route.fullPath;
const { website } = defaults.value[locale.value];
const { metaDescription, metaTitle } = page.value;

useServerSeoMeta({
	twitterCard: metaDescription || '',
	twitterSite: currentUrl || '',
	twitterTitle: metaTitle || '',
	twitterDescription: metaDescription || '',
	twitterCreator: website.hotelName || '',
	twitterImage: website.structuredImage || '',
	ogTitle: metaTitle || '',
	ogUrl: currentUrl || '',
	ogImage: website.structuredImage || '',
	ogDescription: metaDescription || '',
	ogSiteName: website.hotelName || '',
});

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'Hotel',
				'@id': `${config.public.siteUrl}#hotel`,
				image: website.structuredImage,
				url: config.public.siteUrl,
				name: website.hotelName,
				logo: `${config.public.siteUrl}/images/logo.png`,
				telephone: website.phone,
				email: website.email,
				address: {
					'@type': 'PostalAddress',
					streetAddress: `${website.street} ${website.number}, ${website.city}`,
					postalCode: website.postalCode,
				},
			}),
		},
	],
});
</script>
